import { useEffect, useState } from "react";
import { FiChevronDown, FiChevronUp } from "react-icons/fi";
import { FiPlay, FiPause } from "react-icons/fi";
import { useNavigate } from "react-router-dom";
import { useLanguage } from "../Hooks/useLanguage";
import { FiZap } from 'react-icons/fi';

const truncateText = (text: string, limit: number = 40) => {
  return text.length > limit ? text.substring(0, limit) + '...' : text;
};

const countryLanguageMap: { [key: string]: string } = {
  eng: "English",
  fra: "French",
  spa: "Spanish",
  deu: "German",
  ita: "Italian",
  por: "Portuguese",
};

const countryMap: { [key: string]: string } = {
  ng: "Nigeria",
  za: "South Africa",
  eg: "Egypt",
  ke: "Kenya",
  gh: "Ghana",
  dz: "Algeria",
  ma: "Morocco",
  ug: "Uganda",
  et: "Ethiopia",
  tz: "Tanzania",
  ci: "Ivory Coast",
  sn: "Senegal",
  cd: "Democratic Republic of the Congo",
  zw: "Zimbabwe",
  cm: "Cameroon",
  mz: "Mozambique",
  na: "Namibia",
  zm: "Zambia",
  mg: "Madagascar",
  bf: "Burkina Faso",
};

export interface CintOpportunity {
  c_project_id: number;
  c_target_group_id: number;
  conversion_rate: string;
  country: string;
  country_language: string;
  cpi: string;
  delay_crediting: boolean;
  desktop_conversion_rate: string;
  entry_link: string;
  is_pmp: boolean;
  loi: number;
  match_to_qualify: boolean;
  mobile_conversion_rate: string;
  name: string;
  order: {
    loi: number;
    ir: number;
  };
  platform_types: any[];
  project_id: number;
  project_returns?: {
    total_completes: number;
    cpi: number;
    revenue: number;
  };
  qualifications: any[];
  quotas: any[];
  remaining_completes: number;
  rewards?: {
    personId: number;
    amount: number;
    currency: string;
    uuid: string;
    status: "PENDING" | "COMPLETED";
  }[];
  study_type: string | null;
  survey_groups_ids: number[];
  tentative_payout: boolean;
  metrics?: {
    total_entrants: number;
    total_completes: number;
    total_terminates: number;
    conversion_rate: number;
    avg_completion_time: number;
    rpc?: number;
  };
  projectStatus: "LIVE" | "PAUSED";
}

interface Props {
  opportunity: CintOpportunity;
  isOpen: boolean;
  onToggle: () => void;
  onStatusChange: (
    projectId: number,
    status: "LIVE" | "PAUSED"
  ) => Promise<void>;
}

function formatCountryLanguage(countryLanguage: string): string {
  const [language, country] = countryLanguage.split("_");

  const formattedLanguage =
    countryLanguageMap[language] || language.toUpperCase();
  const formattedCountry = countryMap[country] || country.toUpperCase();

  return `${formattedLanguage} (${formattedCountry})`;
}

function CintOpportunityItem({
  opportunity,
  isOpen,
  onToggle,
  onStatusChange,
}: Props) {
  const [isLoading, setIsLoading] = useState(false);
  const [currentStatus, setCurrentStatus] = useState(opportunity.projectStatus);
  const { language } = useLanguage();
  const navigate = useNavigate();

  const handleStatusToggle = async (e: React.MouseEvent) => {
    e.stopPropagation(); // Prevent accordion from toggling

    try {
      setIsLoading(true);
      const newStatus = currentStatus === "LIVE" ? "PAUSED" : "LIVE";
      await onStatusChange(opportunity.project_id, newStatus);
      setCurrentStatus(newStatus); // Update local state after successful API call
    } catch (error) {
      console.error("Failed to update status:", error);
      // Optionally show error toast/message here
    } finally {
      setIsLoading(false);
    }
  };
  // Update local state when prop changes
  useEffect(() => {
    setCurrentStatus(opportunity.projectStatus);
  }, [opportunity.projectStatus]);

  const handleViewAllRewards = (e: React.MouseEvent) => {
    e.stopPropagation()
    if (opportunity.rewards) {
      const formattedRewards = opportunity.rewards.map(reward => ({
        ...reward,
        projectId: opportunity.project_id,
        projectName: opportunity.name,
        createdAt: new Date().toISOString()
      }));
      
      navigate(`/${language}/admin/cint/rewards?projectId=${opportunity.project_id}`, {
        state: { rewards: formattedRewards }
      });
    }
  };

  const pendingRewardsCount = opportunity.rewards?.filter(
    reward => reward.status === "PENDING"
  ).length || 0;
  return (
    <div className="border border-zinc-200 rounded-lg bg-white overflow-hidden">
      {/* Accordion Header - Always Visible */}
      <button
        onClick={onToggle}
        className="w-full px-6 py-4 flex items-center justify-between hover:bg-zinc-50 transition-colors"
      >
        <div className="text-left flex flex-col space-y-1 items-start">
          <div className="flex flex-row items-center space-x-1">
          <h3 className="font-medium text-zinc-900 truncate max-w-[300px] whitespace-nowrap overflow-hidden" title={opportunity.name}>
    {opportunity.name}
</h3>
            <p>•</p>
            <button
              disabled={isLoading}
              onClick={handleStatusToggle}
              className={`
                flex px-3 py-1 text-xs font-medium rounded-full 
                transition-colors duration-200
                ${
                  isLoading
                    ? "bg-gray-100 text-gray-500 cursor-not-allowed"
                    : currentStatus === "LIVE"
                    ? "bg-green-700/20 text-green-700 hover:bg-green-700/30"
                    : "bg-yellow-700/20 text-yellow-700 hover:bg-yellow-700/30"
                }
              `}
            >
              <div className="flex items-center space-x-1">
                {isLoading ? (
                  <>
                    <svg
                      className="animate-spin h-3 w-3 mr-1"
                      viewBox="0 0 24 24"
                    >
                      <circle
                        className="opacity-25"
                        cx="12"
                        cy="12"
                        r="10"
                        stroke="currentColor"
                        strokeWidth="4"
                      />
                      <path
                        className="opacity-75"
                        fill="currentColor"
                        d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                      />
                    </svg>
                    <span>Updating...</span>
                  </>
                ) : (
                  <>
                    {currentStatus === "LIVE" ? (
                      <>
                        <FiPause className="w-3 h-3" />
                        <span>Pause</span>
                      </>
                    ) : (
                      <>
                        <FiPlay className="w-3 h-3" />
                        <span>Start</span>
                      </>
                    )}
                  </>
                )}
              </div>
            </button>

            {/* process rewards */}
            <button
              onClick={handleViewAllRewards} // Prevent accordion toggle
              className={`
                flex px-3 py-1 text-xs font-medium rounded-full 
                transition-colors duration-200 ml-2
                ${pendingRewardsCount > 0 
                  ? 'bg-blue-700/20 text-blue-700 hover:bg-blue-700/30'
                  : 'bg-zinc-100 text-zinc-600 hover:bg-zinc-200'
                }
                relative
              `}
            >
              <div className="flex items-center space-x-1">
                <FiZap className="w-3 h-3" />
                <span>Rewards</span>
                {pendingRewardsCount > 0 && (
                  <span className="absolute -top-1 -right-1 bg-red-500 text-white text-xs rounded-full w-4 h-4 flex items-center justify-center">
                    {pendingRewardsCount}
                  </span>
                )}
              </div>
            </button>
          </div>
          <div className="flex flex-row items-center text-zinc-500 space-x-1">
            <p className="text-sm ">
              CPI: ${Number(opportunity.cpi).toFixed(2)} • LOI:{" "}
              {opportunity.loi}min
            </p>
          </div>
        </div>
        <div className="flex items-center space-x-4">
          <div className="text-right mr-4">
            <p className="text-sm font-medium text-zinc-900">
              {opportunity.remaining_completes} spots
            </p>
            <p className="text-sm text-zinc-500">
              {(Number(opportunity.conversion_rate) * 100).toFixed(1)}%
              conversion
            </p>
          </div>
          {isOpen ? (
            <FiChevronUp className="text-zinc-500" />
          ) : (
            <FiChevronDown className="text-zinc-500" />
          )}
        </div>
      </button>

      {/* Accordion Content - Shown when expanded */}
      <div
        className={`
            overflow-hidden transition-all duration-200 ease-in-out
            ${isOpen ? "max-h-[2000px] opacity-100" : "max-h-0 opacity-0"}
          `}
      >
        <div className="px-6 py-4 border-t border-zinc-100">
          {/* Main Stats Grid */}
          <div className="grid grid-cols-2 md:grid-cols-4 gap-4 mb-6">
            <div className="bg-zinc-50 p-3 rounded-lg">
              <p className="text-sm text-zinc-500">Country</p>
              <p className="font-medium">
                {formatCountryLanguage(opportunity.country_language) || "N/A"}
              </p>
            </div>
            <div className="bg-zinc-50 p-3 rounded-lg">
              <p className="text-sm text-zinc-500">Project ID</p>
              <p className="font-medium">{opportunity.project_id}</p>
            </div>
            <div className="bg-zinc-50 p-3 rounded-lg">
              <p className="text-sm text-zinc-500">IR Rate</p>
              <p className="font-medium">{opportunity.order.ir}%</p>
            </div>
            <div className="bg-zinc-50 p-3 rounded-lg">
              <p className="text-sm text-zinc-500">LOI</p>
              <p className="font-medium">{opportunity.order.loi} min</p>
            </div>
          </div>
          <div className="flex flex-col bg-zinc-100 p-4 rounded-xl space-y-4">
            {/* Conversion Rates */}
            <div className="mb-6">
              <h4 className="text-sm font-medium text-zinc-700 mb-3">
                Conversion Rates
              </h4>
              <div className="grid grid-cols-3 gap-4">
                <div>
                  <p className="text-sm text-zinc-500">Overall</p>
                  <p className="font-medium">
                    {(Number(opportunity.conversion_rate) * 100).toFixed(1)}%
                  </p>
                </div>
                <div>
                  <p className="text-sm text-zinc-500">Mobile</p>
                  <p className="font-medium">
                    {(Number(opportunity.mobile_conversion_rate) * 100).toFixed(
                      1
                    )}
                    %
                  </p>
                </div>
                <div>
                  <p className="text-sm text-zinc-500">Desktop</p>
                  <p className="font-medium">
                    {(
                      Number(opportunity.desktop_conversion_rate) * 100
                    ).toFixed(1)}
                    %
                  </p>
                </div>
              </div>
            </div>

            {/* Metrics Section */}
            {opportunity.metrics && (
              <div className="mb-6">
                <h4 className="text-sm font-medium text-zinc-700 mb-3">
                  Performance Metrics
                </h4>
                <div className="grid grid-cols-2 md:grid-cols-4 gap-4">
                  <div>
                    <p className="text-sm text-zinc-500">Total Entrants</p>
                    <p className="font-medium">
                      {opportunity.metrics.total_entrants}
                    </p>
                  </div>
                  <div>
                    <p className="text-sm text-zinc-500">Total Completes</p>
                    <p className="font-medium">
                      {opportunity.metrics.total_completes}
                    </p>
                  </div>
                  <div>
                    <p className="text-sm text-zinc-500">Total Terminates</p>
                    <p className="font-medium">
                      {opportunity.metrics.total_terminates}
                    </p>
                  </div>
                  <div>
                    <p className="text-sm text-zinc-500">Completion Rate</p>
                    <p className="font-medium">
                      {opportunity.metrics.conversion_rate}%
                    </p>
                  </div>
                </div>
              </div>
            )}

            {/* Project Returns */}
            {opportunity.project_returns && (
              <div className="mb-6">
                <h4 className="text-sm font-medium text-zinc-700 mb-3">
                  Project Returns
                </h4>
                <div className="grid grid-cols-3 gap-4">
                  <div>
                    <p className="text-sm text-zinc-500">Total Completes</p>
                    <p className="font-medium">
                      {opportunity.project_returns.total_completes}
                    </p>
                  </div>
                  <div>
                    <p className="text-sm text-zinc-500">CPI</p>
                    <p className="font-medium">
                      ${opportunity.project_returns.cpi.toFixed(2)}
                    </p>
                  </div>
                  <div>
                    <p className="text-sm text-zinc-500">Revenue</p>
                    <p className="font-medium">
                      ${opportunity.project_returns.revenue}
                    </p>
                  </div>
                </div>
              </div>
            )}

            {/* Recent Rewards */}
            {opportunity.rewards && opportunity.rewards.length > 0 && (
        <div className="mb-6">
          <div className="flex justify-between items-center mb-3">
            <h4 className="text-sm font-medium text-zinc-700">
              Recent Rewards
            </h4>
            
          </div>
          <div className="overflow-x-auto">
            <table className="min-w-full divide-y divide-zinc-200">
              <thead>
                <tr className="text-xs text-zinc-500">
                  <th className="text-left py-2">Amount</th>
                  <th className="text-left py-2">Currency</th>
                  <th className="text-left py-2">Status</th>
                  <th className="text-left py-2">ID</th>
                </tr>
              </thead>
              <tbody className="divide-y divide-zinc-100">
                {opportunity.rewards.slice(0, 3).map((reward) => (
                  <tr key={reward.uuid} className="hover:bg-zinc-50">
                    <td className="py-2 font-medium">
                      ${reward.amount.toFixed(2)}
                    </td>
                    <td className="py-2">{reward.currency}</td>
                    <td className="py-2">
                      <span
                        className={`px-2 py-1 rounded-full text-xs ${
                          reward.status === "PENDING"
                            ? "bg-yellow-100 text-yellow-800"
                            : "bg-green-100 text-green-800"
                        }`}
                      >
                        {reward.status}
                      </span>
                    </td>
                    <td className="py-2 text-zinc-500 text-sm font-mono">
                      {reward.uuid.slice(0, 8)}...
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default CintOpportunityItem;
