// Similar structure for other status pages: ScreenOut.tsx, QuotaFull.tsx, etc.
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import { useLanguage } from '../../Hooks/useLanguage';
import QualificationService from '../../Api/Routes/Qualification/Qualification';
import LoadingOverlay from '../../Components/LoadingOverlay';
import CustomAlert from '../../Components/CustomAlert';

const mapStatusToToluna = (status: string): string => {
    switch (status.toUpperCase()) {
        case 'complete':
            return '0';
        case 'screen-out':
        case 'terminate':  // Adding alternate status
            return '1';
        case 'quota-full':
            return '2';
        case 'qisqualified':
            return '3';
        default:
            return 'closed';  // Default to closed/terminated
    }
};

interface TolunaResponseParams {
    SurveyID: string;
    SurveyName?: string;
    WaveID: string;
    MemberCode: string;
    completionStatus: string;
    Source: string;
    customParam?: string;
    Status: string;
    [key: string]: string | undefined;
}

interface TolunaRedirectLayoutProps {
    status: string;
    tolunaStatus?:string
}


const TolunaRedirectLayout: React.FC<TolunaRedirectLayoutProps> = ({ status, tolunaStatus }: TolunaRedirectLayoutProps) => {
    const { t } = useTranslation();
    const [searchParams] = useSearchParams();

    const location = useLocation();
    const navigate = useNavigate();
    const { language } = useLanguage();
    const [loading, setLoading] = useState(true);
    const [alert, setAlert] = useState<{ type: 'error' | 'success'; message: string } | null>(null);
    const qualificationService = new QualificationService();

    useEffect(() => {
        const queryParams = new URLSearchParams(location.search);
        const params: TolunaResponseParams = {
            SurveyID: searchParams.get('SurveyID') || '',
            WaveID: queryParams.get('WaveID') || '',
            MemberCode: queryParams.get('MemberCode') || '',
            completionStatus: queryParams.get('completionStatus') || '',
            Source: queryParams.get('Source') || '',
            Status: status,
            SurveyName: queryParams.get('SurveyName') || "",
        };

        // http://localhost:3000/en/tln-limit-reached/?Source=toluna&completionStatus=5&SurveyID=dgsdfgsdfg&WaveID=sfdgsdfg&MemberCode=sdfgsfdg&customParam=gsfdgsfdg&SurveyName=23098sdaofips

        const payload = {
            SurveyId: params.SurveyID,
            WaveId: params.WaveID,
            MemberCode: params.MemberCode,
            Status: params.Status,
            SurveyName: params.SurveyName,
            Source: "toluna",
            completionStatus: mapStatusToToluna(params.completionStatus)
        };

        queryParams.forEach((value, key) => {
            if (!(key in params)) {
                params[key] = value;
            }
        });


        const updateRespondentStatus = async () => {
            try {
                await qualificationService.clientTolunaUpdateRespondentStatus({
                    ...payload,
                });
                setLoading(false);
                navigate(`/${language}/tln/survey-result`, { state: { status: tolunaStatus } });
            } catch (error) {
                console.error('Failed to update respondent status:', error);
                setLoading(false);
                setAlert({ type: 'error', message: t('handleRedirect.update_failed') });
            }
        };

      updateRespondentStatus();

        window.history.replaceState({}, document.title, location.pathname);

    }, [location.search, navigate, language, t]);
    return (
        <div>
            {loading && <LoadingOverlay />}
            {alert && <CustomAlert type={alert.type} message={alert.message} onClose={() => setAlert(null)} />}
            {!loading && !alert && <LoadingOverlay />}
        </div>
    )
};

export default TolunaRedirectLayout